import { FawkesCertInfo } from "../shared/CertDropdown";

export interface RestAPI {
    [param: string]: string | number | boolean;
}

export const RequestMethods: AppAcclMap<string> = {
    get: 'GET',
    post: 'POST',
    delete: 'DELETE',
    put: 'PUT',
    patch: 'PATCH'
}

export interface AddOnInfo {
    license_type: string,
    license_expiration: string,
    in_grace_period: boolean
}

export interface FetchStatus {
    status: {
        error_code?: number,
        error_message?: string,
        http_status?: number,
        http_status_message?: string,
        ok?: boolean,
        stacktrace?: string
    }
}

export interface AppAccelAppDef {
    display_name?: string,
    enabled: boolean,
}

export interface RestModifiers {
    mimeType?: string;
    noAuthKey?: boolean;
    accept?: string;
}

export interface AppAccelSettingsCommon {
    enabled?: boolean,
    l7_disabled?: boolean,
    ports?: string[],
    apps?: AppAccelAppDef[],
    decryption_ca_cert_name?: string,
}

export interface AppAccelApps {
    item: {
        apps: AppAccelAppDef[]
    }
}
export interface AppAccelSettingReq {
    app_acceleration: AppAccelSettingsCommon
}

export interface AppAccelEnableRequest {
    app_acceleration: {
        enabled: boolean
    }
}

export interface AppAccelV2EnableRequest {
    onramp: [{
        name: 'prisma_access',
        enabled: boolean
    }]
}

export interface AppAccelAppUpdateRequest {
    app_acceleration: {
        apps: AppAccelAppDef[]
    }
}

export interface AppAccelSDWANAppUpdateRequest {
    apps: AppAccelAppDef[]
}

export interface AppAccelCertUpdateRequest {
    app_acceleration: {
        decryption_ca_cert_name: string
    }
}

export interface AppAccelCertCommonUIUpdateRequest {
    onramp: [ { name: string, decryption_ca_cert_name: string }]
}

export interface AppAccelSettingsResp extends FetchStatus {
    // item: AppAccelSettingsCommon
    item: {
        app_acceleration: AppAccelSettingsCommon
    }
}

export interface AppAccelSettingsCommonUIOnRampResp extends FetchStatus {
    // item: AppAccelSettingsCommon
    decryption_ca_cert_name: string,
    name: string,
    enabled: boolean,
    l7_disabled?: boolean,
}

export interface AppAccelSettingsCommonUIAppsResp extends FetchStatus {
    app: string,
    display_name: string,
    enabled: boolean,
}

export interface AppAccelSettingsCommonUIResp extends FetchStatus {
    items: {
        apps: AppAccelSettingsCommonUIAppsResp[],
        onramp: AppAccelSettingsCommonUIOnRampResp[],
        l7_disabled?: boolean,
    },
}

export interface AppAccelCapabilities extends FetchStatus {
    item: {
        app_acceleration: {
            capable: boolean
        }
    }
}

export interface AppAccelAddonLicense extends FetchStatus {
    items: AddOnInfo[]
}

export interface AppAccelAdemInfo {
    enabled?: boolean,                              // only used for backward compatibility
    application_acceleration_enabled?: boolean,
    application_acceleration_tests_enabled?: boolean,
    activation_percentage: number,
    users: string[],
    groups: string[]
}

export interface ADEMLicenseInfoResp {
    id: string,
    clientTenantId: string,
    dssTenantId: string,
    tsgId: string,
    regionId: number,
    regionUrl: string,
    licenseExpiryTs: number,
}

export interface FawkesCertError {
    "errorCode": string,        // "API_I00002",
    "errorMessage": string,     // "The configuration cannot be loaded right now. Please try again later. Reason: [Error: getaddrinfo ENOTFOUND paas-cfgserv-testsn17626_onprem.default]",
    "extra": object,            // {},
    "httpStatusCode": number,   // 500,
    "ok": boolean,              // false,
    "trackingId": string,       // "be683902-f135-4eeb-bbce-0f68ccb35cb2" 
}

export interface FawkesCertInfoResponse {
    "additional-info": string[],
    certificates: FawkesCertInfo[],
    ok: boolean,
    sslDecrypt: {},
    sslDecryptAdditionalInfo: []
}

export type ZRestRequest = 
    | AppAccelSettingReq
    | AppAccelEnableRequest
    | AppAccelAppUpdateRequest 
    | AppAccelCertUpdateRequest

export type ZRestResponse = 
    | AppAccelCapabilities 
    | AppAccelSettingsResp
    | AppAccelApps
    | AppAccelAddonLicense
    | AppAccelAdemInfo
    | ADEMLicenseInfoResp
    | FawkesCertInfoResponse
    | AppAccelSettingsCommonUIResp

export type AppAcclMap<type> = {
    [name: string]: type
}