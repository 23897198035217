import { MessageDescriptor } from "react-intl";

export const en_strs: Record<string, MessageDescriptor> = {
  aacDescription: {
    id: 'aacOverview',
    defaultMessage:
      "App Acceleration speeds up SaaS applications and file downloads to enhance the user experience " +
      "and boost productivity when connecting through Prisma Access.",
  },
  aacAdditionalInfo: {
    id: 'aacAddInfo',
    defaultMessage: 'Additional Information'
  },
  aacTooltipText: {
    id: 'aacCfgToolTip',
    defaultMessage:
      "This enables App Acceleration across all Mobile User - Global Protect gateways and " +
      "Remote Network branches on this tenant.  Proceed with caution.",
  },
  aacConfigTitle: {
    id: 'aacCfgTitle',
    defaultMessage: "App Acceleration",
  },
  aacWelcome: {
    id: 'aacWelcome',
    defaultMessage: "Welcome to App Acceleration!",
  },
  aacToggleWelcome: {
    id: 'aacToggleWelcome',
    defaultMessage: "App Acceleration for Prisma Access",
  },
  aacEnableToggleText: {
    id: 'aacCBM',
    defaultMessage: "Enabled for All Mobile Users -  Global Protect and Remote Networks",
  },
  aacDisableToggleText: {
    id: 'aacCBM',
    defaultMessage: "Disabled for All Mobile Users -  Global Protect and Remote Networks",
  },
  aacSysNotCapable: {
    id: 'aacNotCapable',
    defaultMessage: 'An infrastructure upgrade is required to enable App Acceleration. Please contact Global Customer Support to schedule the upgrade.'
  },
  aacNoLicense: {
    id: 'aacNoLicense',
    defaultMessage: 'This tenant does not have a license for App Acceleration.  Please contact Palo Alto Networks Sales.'
  },
  aacEnabledSuccess: {
    id: 'aacSuccess',
    defaultMessage: 'App Acceleration successfully enabled.'
  },
  aacDisabledSuccess: {
    id: 'aacSuccess',
    defaultMessage: 'App Acceleration successfully disabled.'
  },
  aacAdemEnabledSuccess: {
    id: 'aacADEMSuccess',
    defaultMessage: 'ADEM changes successfully saved.'
  },
  aacAdemDisabledSuccess: {
    id: 'aacADEMSuccess',
    defaultMessage: 'ADEM successfully disabled.'
  },
  aacAdemChangeSuccess: {
    id: 'aacSuccess',
    defaultMessage: 'ADEM update was successful.'
  },
  aacAdemChangeFailed: {
    id: 'aacSuccess',
    defaultMessage: 'ADEM update failed.'
  },
  aacEnablementInProgress: {
    id: 'aacInProgress',
    defaultMessage: 'App Acceleration will be enabled momentarily. Please stand by.'
  },
  aacEnabledFailure: {
    id: 'aacEnablementFailed',
    defaultMessage: 'App Acceleration could not be enabled. Please contact Global Customer Support'
  },
  aacLicenseExpired: {
    id: 'aacLicenseExpired1',
    defaultMessage: 'The license for App Acceleration has expired. Please contact your account representative to renew your license.'
  },
  aacLicenseExpired2: {
    id: 'aacLicenseExpired2',
    defaultMessage: 'The license for App Acceleration has expired. Please contact your account representative to renew your license.'
  },
  ademLicenseExpired: {
    id: 'ademLicenseExpired',
    defaultMessage: 'Your ADEM evaluation license has expired. Please contact your account manager to continue with App Acceleration tests.'
  },
  aacConfirm: {
    id: 'aacConfirm',
    defaultMessage: 'Confirm'
  },

  // ADEM Messages
  aacShowAdvanced: {
    id: 'aacShowAdvanced',
    defaultMessage: 'Show Advanced Options'
  },
  aacHideAdvanced: {
    id: 'aacHideAdvanced',
    defaultMessage: 'Hide Advanced Options'
  },
  aacAdemCheck: {
    id: 'aacAdemCheck',
    defaultMessage: 'Allow tests to collect performance metrics for Mobile Users'
  },
  aacAdemCheckTooltip: {
    id: 'ademToolTip',
    defaultMessage: 'Enables synthetic tests that ADEM uses to collect performance metrics using a downloaded, pre-configured file.'
  },
  aacAdemPercentage: {
    id: 'aacAdemPercentage',
    defaultMessage: 'Select Percentage...'
  },
  aacAdemInstruct: {
    id: 'aacAdemInstruct',
    defaultMessage: 'Select the percentage of users (maximum is 20%) to randomly collect performance metrics during the poll period'
  },

  // ---------
  // Disable App Acceleration Modal
  aacDisableAacModalTitle: {
    id: 'aacDisableAacModalTitle',
    defaultMessage: 'Disable App Acceleration'
  },
  aacDisableAacModalMessage: {
    id: 'aacDisableAacModalMessage',
    defaultMessage: 'Are you sure you want to disable App Acceleration?'
  },
  aacDisableAacModalMessage1: {
    id: 'aacDisableAacModalMessage1',
    defaultMessage: 'Your GlobalProtect users and Remote Network branches will no longer be accelerated. This change takes effect immediately.'
  },
  aacDisable: {
    id: 'aacOk',
    defaultMessage: 'Disable'
  },
  aacCancel: {
    id: 'aacCancel',
    defaultMessage: 'Cancel'
  },

  // ---------
  // ADEM Modal Strings
  aacAdemModalTitle: {
    id: 'aacAdemModalTitle',
    defaultMessage: 'App Acceleration Enabled!'
  },
  aacAdemModalIntro:{
    id: 'aacAdemModalIntro',
    defaultMessage: `You've successfully enabled App Acceleration!`
  },
  aacAdemModalPara1a:{
    id: 'aacAdemModalPara1a',
    defaultMessage: 'Visit the '
  },
  aacAdemModalPara1b:{
    id: 'aacAdemModalPara1b',
    defaultMessage: 'Accelerated Applications page'
  },
  aacAdemModalPara1c:{
    id: 'aacAdemModalPara1c',
    defaultMessage: ' to see the App Acceleration speed boost.'
  },
  aacAdemModalPara2:{
    id: 'aacAdemModalPara2',
    defaultMessage: 
      `We've also enabled the collection of metrics through a split tunnel to perform synthetic tests ` + 
      `through ADEM. These tests run in the background on a subset of agents and have no effect on your network speeds or security posture.`
  },
  aacGotIt:{
    id: 'aacGotIt',
    defaultMessage: 'Got It!'
  },
  // ---------
  // ADEM Modal
  // The next 3 are all in one paragraph but 'aacAdemModalPara3b' needs to be bold. 
  aacAdemModalPara3a:{
    id: 'aacAdemModalPara3a1',
    defaultMessage: 'To modify or disable the tests, open '
  },
  aacAdemModalPara3b:{
    id: 'aacAdemModalPara3a2',
    defaultMessage: 'Advanced Options '
  },
  aacAdemModalPara3c:{
    id: 'aacAdemModalPara3a',
    defaultMessage: 'on this page.'
  },

  // strings for phase 2 app table
  aacAppsAcceleratedApps:{
    id: 'aacAppsAcceleratedApps',
    defaultMessage: 'Accelerated Apps'
  },
  aacAppsAccelInfo:{
    id: 'aacAppsAccelInfo',
    defaultMessage: 'App Acceleration will apply SSL decryption to accelerated applications.'
  },
  aacAppsValidCert: {
    id: 'aacAppsValidCert',
    defaultMessage: 'Valid Root Cert'
  },
  aacAppGoToCertMgmt: {
    id: 'aacAppGoToCertMgmt',
    defaultMessage: 'Go to Certificate Manager Page'
  },
  aacAppAcceleratedCol: {
    id: 'aacAppAcceleratedCol',
    defaultMessage: 'Accelerated'
  },
  aacApplicationCol: {
    id: 'aacApplicationCol',
    defaultMessage: 'Application'
  },
  aacCertNoRootCert: {
    id: 'aacCertNoRootCert',
    defaultMessage: 'No Root CA!'
  },
  aacImportant: {
    id: 'aacImportant',
    defaultMessage: 'IMPORTANT!'
  },
  aacImportantMessage: {
    id: 'aacImportantMessage',
    defaultMessage: "After you upload the root CA for App Acceleration and push the configuration, " + 
                    "select the root CA above. Wait approximately 1 hour to enable your apps as Prisma " + 
                    "Access propagates the root CA to mobile users and App Acceleration creates the " + 
                    "domain-specific certificates per app. You can still use the apps while the certificates " + 
                    "are being created and enable App Acceleration globally, but do not enable App Acceleration " + 
                    "at the per-app level or you could receive an SSL error."
  },
  aacImportantMessagePanoramaTenant: {
    id: 'aacImportantMessagePanoramaTenant',
    defaultMessage: "Be sure that you specify the CA certificate name exactly in this screen, or the domain-specific " + 
                    "certificates cannot be created and you will receive an SSL error if you enable App Acceleration " + 
                    "for specific apps."
  },
  aacNoCertInfo: {
    id: 'aacNoCertInfo',
    defaultMessage: 'No Cert Information',
  },

  aacEnterPanoramaCert: {
    id: 'aacEnterPanoramaCert',
    defaultMessage: 'Enter Panorama Root CA Name'
  },

  // Panorama managed tenant confirmation strings (first cert)
  aacPanoramaConfTitle: {
    id: 'aacPanoramaConfTitle',
    defaultMessage: 'Confirm Panorama Root CA Name'
  },
  aacPanoramaConfMsg: {
    id: 'aacPanoramaConfMsg',
    defaultMessage: `Are you sure you want to use the following Panorama Root CA certificate for App Acceleration?`
  },
  aacPanoramaConfLabel: {
    id: 'aacPanoramaConfLabel',
    defaultMessage: 'Panorama Root CA Name'
  },
  aacPanoramaConfMsg1: {
    id: 'aacPanoramaConfMsg1',
    defaultMessage: 'Make sure that the name you specify exactly matches the CA name in Panorama. In addition, ' + 
                    'make sure that you have pushed your configuration changes in Prisma Access, and allow ' + 
                    'sufficient time for the new CA to be pushed to mobile users.'
  },
  // Panorama managed tenant confirmation strings (changing cert)
  aacChangingPanoramaRootCATitle: {
    id: 'aacChangingPanoramaRootCATitle',
    defaultMessage: 'Confirm Panorama Root CA Name Change'
  },
  aacChangePanoramaConfMsg: {
    id: 'aacChangePanoramaConfMsg',
    defaultMessage: `Are you sure you want to change your Panorama Root CA certificate for App Acceleration?`
  },
  aacChangePanoramaConfMsg1: {
    id: 'aacPanoramaConfMsg1',
    defaultMessage: 'Make sure that the name you specify exactly matches the CA name in Panorama. In addition, ' + 
                    'make sure that you have pushed your configuration changes in Prisma Access, and allow ' + 
                    'sufficient time for the new CA to be pushed to GlobalProtect users.'
  },

  // changing the root CA for a fawkes managed tenant
  aacChangingRootCATitle: {
    id: 'aacChangingRootCATitle',
    defaultMessage: 'Change Root CA Certificate'
  },
  aacChangingRootCAMsg: {
    id: 'aacChangingRootCAMsg',
    defaultMessage: 'Are you sure you want to use the following Root CA certificate for App Acceleration?'
  },
  aacCertConfLabel: {
    id: 'aacCertConfLabel',
    defaultMessage: 'Root CA Name'
  },
  aacChangingRootCAMsg1: {
    id: 'aacChangingRootCAMsg1',
    defaultMessage: 'Make sure that you have pushed your configuration changes in Prisma Access, and allow ' +
                    'sufficient time for the new certificate to be pushed to GlobalProtect and to the end users.'
  },

  // these are used to build the select certificate strings.  A helper function is used to put the string together
  aacCertSelItem1: {
    id: 'aacCertSelItem1',
    defaultMessage: ' - Exp. ' 
  },
  aacCertSelItem2a: {
    id: 'aacCertSelItem2',
    defaultMessage: ' days ago' 
  },
  aacCertSelItem2b: {
    id: 'aacCertSelItem2B',
    defaultMessage: ' Days'
  },
  aacCertUpdateFailure: {
    id: 'aacCertUpdateFailure',
    defaultMessage: 'Decryption CA CertName cannot be changed. Please contact Customer Support'
  },
  aacAppUpdateFailure: {
    id: 'aacCertUpdateFailure',
    defaultMessage: `The application's state can not be changed at this time. Please contact Customer Support`
  },

  serverErrorGettingCerts: {
    id: 'serverErrorGettingCerts',
    defaultMessage: 'The certificate information cannot be loaded at this time.  Please try again later.'
  },
  // generic error messages
  TenantDoesNotExist: { // 403
    id: 'aacErrCBM',
    defaultMessage: "The tenant can not be found. Please contact Global Customer Support.",
  },
  serverDataChanged: {  // 404
    id: 'aacErrCBM',
    defaultMessage: "The tenant cannot be found.  Please contact Global Customer Support.",
  },
  serverError: {    // 500
    id: 'aacErrCBM',
    defaultMessage: "A system error has occurred.  Please contact Global Customer Support.",
  },
};
