export const makeQueryParams = (url: string, params: Record<string, unknown> | string): string => {
    let paramStr = '';
    
    if (typeof params === 'object') {
        const paramKeys = Object.keys(params);
        for (let i = 0, len = paramKeys.length; i < len; i++) {
            if (paramStr.length > 0) {
            paramStr += '&';
            }
            const key = paramKeys[i];
            paramStr += `${key}=${params[key]}`;
        }
    } else if (typeof params === 'string') {
        paramStr = params;
    }

    return `${url}?${paramStr}`;
}

export const NaaURLs = {
    naaHome: '/workflows/app-acceleration/',
    sparkyCertManagement: '/manage/objects/certificate-management?container=prisma-access',

    // naaAppTechInfo is a dup of naaTechInfo.  However that may change which is why I am keeping both.
    naaInsightLink: '/dashboards/sase-health',
    naaAppTechInfo: 'https://docs.paloaltonetworks.com/prisma-access/administration/app-acceleration-in-prisma-access',
    naaTechInfo:    'https://docs.paloaltonetworks.com/prisma-access/administration/app-acceleration-in-prisma-access',

    naaUrlModifierCommonUI: '/sse/app_accl/',
    naaUrlModifier: '/sse/app_accl/',
    naaUrlModifierStg1: '/sse/app_accl/stg1/',
    naaUrlModifierStg2: '/sse/app_accl/stg2/',
    
    serverNaaSettingsCommonUI: 'v2/settings',
    serverNaaSettings: 'v1/settings',
    serverNaaGetCapabilities: 'v1/capabilities',
    serverNaaGetTenantAddonLicenses: 'v1/license_addons',
    serverGetAdemLicense: (tid: string) => {return `/api/v3/t/${tid}`; },
    serverAdemAACLInfo: (subTid: string) => { return `/api/v3/st/${subTid}/application-acceleration`; },
    serverFawkesCertInfo: (folder: string) => { 
        return `/api/config/v9.2/Device/CertificateManagement/CertificatesInfo?type=container&folder=${folder}&isBpa=no`; },
}

export default NaaURLs;
