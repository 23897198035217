import { FormattedMessage , MessageDescriptor } from "react-intl";

import { en_strs } from "./en/strings";

export const Supported_Locales: Record<string, Record<string, MessageDescriptor>> = {'en': en_strs };

let strs: Record<string, MessageDescriptor> = en_strs;

export const setLanguage = (lang: string): void => {
  // console.log(`naa: setLanguage to ${lang}`);
  strs = (Supported_Locales[lang] === undefined) ? en_strs : Supported_Locales[lang];
}

export const IntlString: React.FunctionComponent<{md: MessageDescriptor, cls?: string}> = ({ md, cls }) => {
    const clsName = cls ? cls : '';
    return (md && md.id && md.defaultMessage) ? 
            <span className={cls}><FormattedMessage id={md.id} defaultMessage={md.defaultMessage} /></span>
         : <span></span>;
}

export const getStrings = (): Record<string, MessageDescriptor> => {
    return strs;

}