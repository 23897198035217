import { CheckIcon, ClockIcon } from "@panwds/icons";
import { Button, Select, SelectItem } from "@panwds/react-ui";
import { useState } from "react";

const thirtyDays = ((1000 * 3600 * 24) * 30);

export enum CertState {
    // These apply to global cert state only
    NO_STATE = 'NO_STATE',      // Tenant hasn't selected a cert and certs not loaded (could be 
                                // just hasn't happened yet or there was an error loading certs)
    CERT_SELECTED_NO_CERT_LIST = "CERT_SELECTED_NO_CERT_LIST",  // Tenant has selected a cert but 
                                                                // can't get cert list
    NO_CERT = 'NO_CERT_SELECTED',       // Cert List loaded but no cert selected

    // These apply to global cert state and individual cert state
    EXPIRED_CERT = 'EXPIRED_CERT',
    NORMAL = 'NORMAL',
    EXPIRING = 'EXPIRING',
    NOT_LOADED = 'NOT_LOADED_YET',
}

export type CertStrings = {
    expiryCert: string;             //  - ' - exp. ' 
    expiredDays: string;            //  - ' days ago' 
    expiringDays: string;           //  - ' days'
    noRootCert: string;             //  - 'No Root CA!'
    noCertInfo: string;             //  - 'No Cert Information',
};

// Note:  We want certs that are CAs and have a private key.
export interface FawkesCertInfo {
    "@loc": string;                                // "Mobile Users Container",
    "@name": string;                               // "jira_demotenant_com",
    "@uuid": string;                               // "d482ffca-c94c-4d99-959c-19279725e6f9",
    "@type": string;                               // "container",
    "subject-hash": string;                        // "53b43cad",
    "issuer-hash": string;                         // "e3bc0f8e",
    "not-valid-before": string;                    // "Dec 20 18:09:45 2023 GMT",
    "issuer": string;                              // "/CN=Agentless root ca",
    "not-valid-after": string;                     // "Dec 19 18:09:45 2024 GMT",
    "common-name": string;                         // "jira.demotenant.com",
    "expiry-epoch": string;                        // "1734631785",
    "ca": string;                                  // "no",
    "subject": string;                             // example: "/CN=jira.demotenant.com",
    "public-key": string;
    "algorithm": string;                           // "RSA",
    "private-key": string;
    "common-name-int": string;                     // "jira.demotenant.com",
    "subject-int": string;                         // "CN = jira.demotenant.com"
}

export interface FawkesCertInfoUI extends FawkesCertInfo {
    certState: CertState;
}

interface CertDDProps {
    certName: string,
    disabled: boolean,
    certs: FawkesCertInfo[] | FawkesCertInfoUI[],
    certStrings: CertStrings,
    onCertChanged: (newCertIdx: number) => void,
}

interface CertBtnCssInfo {
    css: string,
    text?: string,
}

//-----
// utility to determine the number of days between 2 dates:
export const diffBetweenDates = (date1: Date, date2: Date): number => {
    const differenceInTime = date2.getTime() - date1.getTime();
    const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));

    return differenceInDays;
}

// takes list of certs and returns a list of certs with state info
export const buildCertItems = (certInfoList: FawkesCertInfo[] | FawkesCertInfoUI[] | undefined, certName: string): {newCertList: FawkesCertInfoUI[], selectedCertIndex: number} => {
    const now = new Date().getTime();
    const nowPlus30 = now + thirtyDays;

    let newCertList: FawkesCertInfoUI[] = [];
    let selectedCertIndex = -1;
    
    let usePassedCertList = false;
    if (certInfoList && certInfoList?.length > 0) {
        if ((certInfoList as FawkesCertInfoUI[])[0].certState !== undefined) {
            usePassedCertList = true;
        }
    } 

    if (usePassedCertList) {
        newCertList = certInfoList as FawkesCertInfoUI[];
        selectedCertIndex = certInfoList?.findIndex(cert => cert['@name'] === certName) ?? -1;
    } else {
        const nCertInfoList = certInfoList && certInfoList?.length > 0 ? certInfoList : [];
        for (let i=0, len=nCertInfoList.length; i < len; i++) {
            const cert = nCertInfoList[i]
    
            // ignore the cert if it is not valid yet
            const notBefore = new Date(cert["not-valid-before"]).getTime();
            if (notBefore > now) {
                continue;
            }
    
            // ignore the cert if it is expired but not the current cert
            const expired = new Date(cert["not-valid-after"]).getTime();
            if (expired < now && cert['@name'] !== certName) {
                continue;
            }
    
            // select the cert if it is a CA and it has a private key;
            if ((cert["ca"] === "yes") && (cert["private-key"] !== undefined)) {
                const newCert: FawkesCertInfoUI = {...(cert as FawkesCertInfoUI)};
                newCert.certState =  (nowPlus30 > expired) ? CertState.EXPIRING : CertState.NORMAL;
                newCert.certState = (now > expired) ? CertState.EXPIRED_CERT : newCert.certState;
                newCertList.push(newCert);
    
                // save the index if this is the current cert for the tenant
                if (cert['@name'] === certName) {
                    selectedCertIndex = newCertList.length - 1;
                }
            }
        }
        newCertList = newCertList.sort((a, b) => { return (a["@name"] > b["@name"]) ? 1 : -1 })
    }

    return {newCertList, selectedCertIndex};
}

const buildCertListEntries = ( newCertList: FawkesCertInfoUI[], selectedCertIndex: number, strs: CertStrings): SelectItem[] => {                    
    const selectCertItems: SelectItem[] = [];
    const haveSelectedCert = selectedCertIndex >= 0;
    const selectedCert = haveSelectedCert ? newCertList[selectedCertIndex] : undefined;
    const certNameLen = haveSelectedCert ? selectedCert!["@name"].length : 0

    const buildCertSelectItem = (cert: FawkesCertInfoUI): SelectItem => {
        const expiry = new Date(cert["not-valid-after"]);
        const now = new Date();
        const numDays = diffBetweenDates(now, expiry);

        let str: Record<string, JSX.Element> = { text: <span></span>};

        if (numDays < 0) {
            str = {text: <span className="naa-text-red-600">
                {`${cert["@name"]}${strs.expiryCert}${-numDays}${strs.expiredDays}`}</span>};
        } else {
            const cssClass = numDays <= 30 ? "naa-font-bold naa-text-orange-400" : ""
            const s = <span className={cssClass}>{`${cert["@name"]}${strs.expiryCert}`}{numDays}{`${strs.expiringDays}`}</span>;
            str = {text: s};
        }
        const statusIcon = getCertIcon( cert );
        return { value: str, context: { status: statusIcon }};
    }

    const getCertIcon = (cert: FawkesCertInfoUI) => {
        const cState = cert.certState;
        const statusIcon =  ( cState === CertState.EXPIRED_CERT ) ? <span></span> :
                            ( cState === CertState.EXPIRING ) ? <ClockIcon size="md" className="naa-text-orange-400" /> :
                            ( cState === CertState.NORMAL ) ? <CheckIcon size="md" className="naa-text-green-700" /> : 
                            <span></span>;
        return statusIcon;
    }
    
    let savedLen = certNameLen;
    newCertList.forEach((cert) => {
        const cnLen = cert["@name"].length
        savedLen = (savedLen > cnLen) ? savedLen : cnLen;
        selectCertItems.push({value: cert["@name"], context: {text: buildCertSelectItem(cert)}})
    });

    // if (certNameLen === 0 && savedLen > 0) {
    //     setCertNameLen(savedLen)
    // }
    return selectCertItems;
}


const buildCertDrpDownBtn = (selectedCert: SelectItem | undefined, certState: CertState, strs: CertStrings): JSX.Element => {
    let btn = <span></span>;
    const btnClasses: Record<string,CertBtnCssInfo> = {
        NO_STATE: {css: 'naa-cert-btn-bad', text: strs.noCertInfo},
        CERT_SELECTED_NO_CERT_LIST: {css: 'naa-cert-btn-bad', text: strs.noCertInfo },
        NO_CERT_SELECTED: {css: 'naa-cert-btn-bad', text: strs.noRootCert},
        EXPIRED_CERT: {css: 'naa-cert-btn-bad'},
        NORMAL: {css: 'naa-cert-btn-normal'},
        EXPIRING: {css: 'naa-cert-btn-normal'},
    }

    let certText = <span></span>;
    const certDspInfo = btnClasses[certState];
    if (selectedCert) {
        certText = <div>{selectedCert?.context?.text?.context?.status}&nbsp;&nbsp;{selectedCert?.context?.text?.value?.text}</div> 
    } else {
        certText = <div>{certDspInfo.text}</div>;
    }
    
    // const btnText = certDspInfo.text ? certDspInfo.text : 
    btn = <Button isMenu addClassName={certDspInfo.css}>{certText}</Button>;

    return btn;
}

export default function CertSelectDD({certName, certs, disabled, certStrings, onCertChanged}: CertDDProps): JSX.Element {
    const strs = certStrings;
    
    const [ certNameLen, setCertNameLen ] = useState(0);
    const certInfo = buildCertItems(certs, certName);

    const { newCertList, selectedCertIndex} = certInfo;
    const certComboItems = buildCertListEntries(newCertList, selectedCertIndex, certStrings);
    let globalCertState: CertState = (certName.length > 0) ? CertState.CERT_SELECTED_NO_CERT_LIST : CertState.NO_CERT;

    let selectedCertEntry: SelectItem | undefined = undefined;
    let selectedCertName: string = '';

    if (selectedCertIndex >= 0) {
        selectedCertEntry = certComboItems[selectedCertIndex];
        globalCertState = newCertList[selectedCertIndex].certState;
        selectedCertName = selectedCertEntry?.value?.toString();
    }
    const btn = buildCertDrpDownBtn(selectedCertEntry, globalCertState, certStrings);

    // const disabled: boolean = !wasEnabled || appAccelDisabled || appAccelSettings.tenantApps.length === 0};
    const certNameLength = certNameLen ? certNameLen : selectedCertName?.length;
    return <span><Select items={certComboItems} disabled={disabled} selectedItem={selectedCertEntry} fitToItemWidth={true} 
                     customWidth={ certNameLength > 0 ? (certNameLength*8).toString() : "" }  defaultPlacement="bottom"
                     button={btn}
                     onChange={(change) => { 
                        const idx = change.highlightedIndex as number; 
                            onCertChanged(idx);
                        }
                     }
                     formatOption={{
                        view: ((item: SelectItem) => { 
                            const statusIcon = item?.context?.text?.context?.status;
                            return ( <div>{statusIcon}&nbsp;&nbsp;<span className='naa-pt-1'>{item?.context?.text?.value?.text}</span></div>)
                        })
                     }}
                    />
            </span>
}