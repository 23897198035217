import { getState, getAuthState, Entitlements, getCurrentTsgId, getFrameworkVar, getMicroAppVars, actions } from '@sparky/framework';

import { AppAccelAddonLicense, AddOnInfo, ZRestResponse, FetchStatus } from '../data/queryResultsDefinition';
import { Addon_licenses } from './AppAccelContext'

import { appAccelAppID } from './constants';
import { ZGet } from './backend';
import NaaURLs from './urls';
import Logger from './Logger';
import { compat_tsg_ids, dev_environment, deprecated_tsg_ids } from '../env'

import {rnOnlyLicenseTypes} from './constants'
import { LicenseInfoBase, LicEntitlements, SparkyInstanceInfo } from '../data/sparkyDefs';
import { TsgInstances } from '@sparky/framework/build/types/framework/indexedArrays';

const LicenseSingleton = (() => {
    let naaLicense: Addon_licenses | undefined = undefined;
    return {
        getInstance: (): Addon_licenses => {
            if (naaLicense === undefined) {
                naaLicense = {items: [], includeAdem: false, in_grace_period: false, urlEnvModifier: ''}
            }

            return naaLicense;
        }
    }
})()

export const getAACLicenseArray = (): Addon_licenses => {
    return LicenseSingleton.getInstance()
}

export async function hasLicense(): Promise<Boolean> {
    let bHasLicense = false;
    let in_grace_period = true;

    // -------------
    // Why do we need the following code:
    //
    //   The following fixes a compatibility issue that occurred in APP Accel when it first went to QA for v1.
    //   At the time there was no COB support for adding APP Accel to new tenants.  We fixed this by
    //   manually adding the new tenants to the RDS database.  The problem is that by doing this, these
    //   tenants were not known to the COB.  The COB is the source of truth for licenses in sparky.  
    //
    //   What this means is that these tenants were broken.  They are not known to sparky and sparky is where 
    //   the UI checks to see if the current tenant should have access to app accel.  To get around this,
    //   we implemented an ZOS endpoint (license_addons) to manually check the license status of the tenant.
    //   
    //   Meanwhile, SCM UI was being audited for startup speed.  It was decided that no app should make 
    //   API calls during sparky init.  The license_addons call violated this (and also was slow).  By this 
    //   time, there was COB support for app accel.  So now we could get license info from sparky.  So work was
    //   done to switch to using sparky for license checks.  Unfortunately, this screwed QA using the broken 
    //   tenants.  The bad tenants no longer had access to app accel.
    //
    //   There were only a few tenants (which are all in the QA environment) that are broken in this way.  The
    //   current licensing behavior allows for both sparky license checking and backward compatibility for the
    //   broken tenants.  Here is what we do now:
    //   To validate access to APP Accel, we check if the TSG ID has a license in the sparky.  If it does,
    //   we are done.   If not we check TSG ID is in the list of compatible/deprecated TSG IDs.  If it
    //   is, then we make the license_addons call.  If it isn't then we know that this tenant should not
    //   have access to app accel.
    //   

    // Here we check sparky for license
    const bHasFawkesLicense = await sparkyHasLicense();
    if (bHasFawkesLicense) {
        return bHasFawkesLicense;
    }

    // no sparky license.  Time to check if this is a broken/deprecated TSG_ID.
    const usersTsgId = getCurrentTsgId();

    // -----------------
    // More info about ci/cd and env variable integration
    //
    //   In a perfect world we would have access to environment variable which defines the compatible/deprecated 
    //   tenants so we would only have those tenants identified in the QA environment.  In all other environments
    //   the list of exception tenants would be empty.  This would improve app security in production.
    //
    //   Unfortunately, I could never get this to work.  You can see my attempts in the .gitlab-ci.yml file.
    //   I tried to:
    //     - create an environment variable that contained these tsg_ids only in the QA environment.  I
    //       couldn't get that to work.  For some reason, I was not seeing the variable in QA.
    //     - I tried copying environment files so the variables would be there only in QA.  That
    //       didn't work either.
    //     - finally, I hard coded the list of compatible/deprecated tenants in the code.  I store it in
    //       the 'deprecated_tsg_ids' variable.

    // ## If we were able to make sparky environment variables we would have uncommented 
    // ## following 2 lines
    // const appAccelVars = getMicroAppVars("app-accel-config");
    // const compTsgs = appAccelVars?.compat_tsg_ids;
    // console.log(`app-accel-config vars = "${JSON.stringify(appAccelVars)}"`);

    // const compTsgs = compat_tsg_ids && compat_tsg_ids?.length > 0 ? compat_tsg_ids : deprecated_tsg_ids;
    const compTsgs = deprecated_tsg_ids;
    const tsgs = compTsgs !== undefined ? (compTsgs as string).split(",") : [];

    const foundException = tsgs.findIndex(tsg => {return tsg === usersTsgId})
    Logger.info(`foundException, ${foundException}`);
    if (foundException === -1) {
        return false;
    }

    // These modifiers are used for building our URLs.  Based on environment
    // the modifier changes.  We have special ones for staging1 and staging2. 
    // All other environments can use the naaUrlModifier.
    const env: string[] = [
        NaaURLs.naaUrlModifier,
        NaaURLs.naaUrlModifierStg1,
        NaaURLs.naaUrlModifierStg2,
    ];
    
    const licInfo: Addon_licenses = {items: [], includeAdem: false, in_grace_period: true, urlEnvModifier: ''};
    try {
        let response: ZRestResponse | undefined = undefined;
        let err: FetchStatus | undefined = undefined;

        // const licInfo = LicenseSingleton.getInstance();
        for (let i=0, len=env.length; i < len; i++) {
            try {
                let url = env[i] + NaaURLs.serverNaaGetTenantAddonLicenses;
                const queryParams = (i !== 0) ? `?retry=${i}` : '';
                url = url + queryParams;
                response = await ZGet(url, '');
                if (!(response as FetchStatus)?.status?.ok) { throw response }

                licInfo.urlEnvModifier = env[i]
                break;
            }
            catch(e: any) {
                err = e as FetchStatus;
                if (err.status.http_status === 404) {
                    continue;
                }
                throw e;
            }
        }

        if (! response) { throw err}
        const addonLicenses = response as AppAccelAddonLicense;
        if (addonLicenses?.items.length !== 0) {
            bHasLicense = true;

            // save the tenant's licenses
            licInfo.items = addonLicenses.items;

            // check for RN License
            let includeAdem = false;
            addonLicenses.items.forEach((item: AddOnInfo) => {
                const foundRn = rnOnlyLicenseTypes.findIndex((licType) => { return item.license_type === licType })
                includeAdem = includeAdem || foundRn === -1;  
                in_grace_period = in_grace_period && !!(item?.in_grace_period);
            });
            licInfo.includeAdem = includeAdem
            licInfo.in_grace_period = in_grace_period;
        }
    }
    catch(e: any) {
        bHasLicense = false;
        const licInfo = LicenseSingleton.getInstance();
        licInfo.items = [];
        licInfo.includeAdem = false;
        licInfo.in_grace_period = false;
    }

    // actions.setValues({ pa_app_accel_license: bHasFawkesLicense});

    if (bHasFawkesLicense !== bHasLicense) {
        // alert('license mismatch')
        if (bHasLicense) {
            const realLicInfo = LicenseSingleton.getInstance();
            realLicInfo.in_grace_period = licInfo.in_grace_period;
            realLicInfo.includeAdem = licInfo.includeAdem;
            realLicInfo.items = licInfo.items;
            realLicInfo.urlEnvModifier = licInfo.urlEnvModifier;
        }
    }
    return bHasLicense;
}

export async function sparkyHasLicense() {
    let in_grace_period = true;
    let includeAdem = false;
    let validLicense = false;

    // licInfo is local storage for license info.  We don't have context yet so we save it locally.  When we make
    // the capabilities call in AppAccelRoutes (only done once), we get licInfo and store it in context.
    const licInfo = LicenseSingleton.getInstance();
    const authState = getAuthState();

    const instances:  Readonly<TsgInstances> = authState?.instances
    if (instances?.length > 0) {
        const sdwanInstance = instances.get('cgx');
        Logger.info(`found sdwan license, ${sdwanInstance?.status === 'running'}`);
        console.info('SDWAN license found ? - ', sdwanInstance);
        for (let inst=0, instLen=instances.length; inst < instLen; inst++) {
            const appId = instances[inst]?.app_id;
            // only check license for prisma access app_ids
            if (appId !== 'prisma_access_edition' && appId !== 'prisma_access_edition_onprem' && 
                appId !== 'prisma_access_panorama' ) { continue; }

            const entitlements: LicenseInfoBase[] = instances?.get(appId)?.entitlements;

            if (entitlements) {
                licInfo.items.length = 0;
                const items: AddOnInfo[] = [];

                for (let i=0, len=entitlements.length; i < len; i++) {
                    const lic = entitlements[i];
                    Logger.info(`license from sparky '${lic.app_id}'`);
                    console.info(`license from sparky '${lic.app_id}'`);
                    if (lic.app_id === appAccelAppID) {
                        actions.setValues({ pa_app_accel_license: true });
                        console.info(`setting values for PA App Accel licensne in sparky - pa_app_accel_license '${lic.app_id}'`);
                        validLicense = true;
                        const expiration = lic.license_expiration as string;
                        const in_grace = (new Date().getTime()) > (new Date(expiration).getTime());
                        const item: AddOnInfo = {license_type: lic.license_type, license_expiration: expiration, in_grace_period: in_grace}
                        items.push(item)

                        const foundRn = rnOnlyLicenseTypes.findIndex((licType) => { return lic.license_type === licType })
                        includeAdem = includeAdem || foundRn === -1;  
                        in_grace_period = in_grace_period && !!(item?.in_grace_period);
                    }

                    licInfo.items = items;
                    licInfo.includeAdem = includeAdem
                    licInfo.in_grace_period = in_grace_period;
                }
                
                if (licInfo.items.length > 0) {
                    Logger.info(`found App_Accel license, ${appId}`);
                    validLicense = true;
                    break;
                }
            }
            if (licInfo.items.length > 0) {
                break;
            }
        }
    } else {
        validLicense = false;
        const licInfo = LicenseSingleton.getInstance();
        licInfo.items = [];
        licInfo.includeAdem = false;
        licInfo.in_grace_period = false;
    }
    Logger.info(`APP_ACCEL has license = ${validLicense}`);
    console.info('SDWAN license found, APP_ACCEL has license ? - ', validLicense);
    return validLicense;
}