export const appAccelAppID = 'add_app_accl';

// RN Only licenses can't do the ADEM testing
export const rnOnlyLicenseTypes = ['NETACCESSAPPACCLPAE'];
export const MinAppsToShowFilter = 10;
export enum ToastAppearance {
    'clear' = 'clear',
    'info' = 'info',
    'warning' = 'warning',
    'error' = 'error',
    'success' = 'success',
}

export const MUCertFolder = 'Mobile%20Users%20Container';
export const PACertFolder = 'Prisma%20Access&';

export const MAX_LABEL_LENGTH = 63;
export const MIN_APPS_BEFORE_PAGINATION = 25;

// API Gee 'go' error codes.  Taken from GitLab sase-accl-controller project on 1/25/24.
//     Full path to code is 'sase-accl-controller/internal/types/errorcodes.go'
export enum GoError {
    ErrForbidden = 1001,                  // http.StatusForbidden
    ErrInvalidInput = 1002,               // http.StatusBadRequest
    ErrCoreAPICallFailed = 1003,          // HTTP status will be populated from the error object returned from coreapiclient.
    ErrInvalidOutput = 1004,              // HTTP status will be populated from the error object returned from coreapiclient.
    ErrInternalServerError = 1005,        // http.StatusInternalServerError
    ErrPreconditionFailed = 1006,         // http.StatusPreconditionFailed
    ErrMethodNotAllowed = 1007,           // http.StatusMethodNotAllowed
    ErrNotFound = 1008,                   // http.StatusNotFound
    ErrServiceUnavailable = 1009,         // http.StatusServiceUnavailable
}